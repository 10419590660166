<template>
  <div class="banner_area" :style="
      imageSrc
        ? `background-image: url(${imageSrc})`
        : `background-image: url(${imageDomain}/assets/img/banner_booking.png)`
    ">
    <div class="container">
      <div class="banner_body">
        <div class="d-flex justify-content-between">
          <div class="banner_box">
            <div :class="['number', stage == 1 ? 'active' : '']">1</div>
            <h3>{{ $t("booking.details") }}</h3>
          </div>
          <div class="banner_box">
            <div :class="['number', stage == 2 ? 'active' : '']">2</div>
            <h3>{{ $t("booking.invitation") }}</h3>
          </div>
          <div class="banner_box">
            <div :class="['number', stage == 3 ? 'active' : '']">3</div>
            <h3>{{ $t("booking.approval") }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageUrlMixin from '@/utils/imageUrlMixin';
import bannerImageDestination from '@/utils/bannerImageDestination';

export default {
  mixins: [imageUrlMixin, bannerImageDestination],
  props: {
    stage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    imageSrc() {
      // if (this.bannerImage && (typeof this.bannerImage === 'string' || this.bannerImage instanceof String)) {
      //   return this.bannerImage?.replaceAll(' ', '%20');
      // }
      return '';
    },
  },
};
</script>

<style scoped>
.banner_area {
  width: 100%;
  height: 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 34px 0;
}
.banner_area .banner_body {
  position: relative;
}
.banner_area .banner_body::before {
  content: "";
  position: absolute;
  top: 14px;
  left: 0;
  width: 85%;
  height: 3px;
  background: #fff;
  right: 0;
  margin: 0 auto;
}
.banner_area .banner_body .banner_box {
  text-align: center;
  position: relative;
  z-index: 9;
}
.banner_area .banner_body .banner_box .number {
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  line-height: 28px;
  color: #000;
  background: #fff;
  box-sizing: border-box;
  border-radius: 50%;
  font-size: 15px;
  font-weight: 600;
  margin: 0 auto 6px;
}
.banner_area .banner_body .banner_box h3 {
  color: #fff;
  margin: 0 0 0;
  padding: 0 0 0;
  font-size: 15px;
  font-weight: 400;
}
.banner_area .banner_body .banner_box .number.active {
  background: #0061ab;
  color: #fff;
}
</style>
